import { render, staticRenderFns } from "./Submissions.vue?vue&type=template&id=225a54c1&scoped=true&lang=pug&"
import script from "./Submissions.vue?vue&type=script&lang=js&"
export * from "./Submissions.vue?vue&type=script&lang=js&"
import style0 from "./Submissions.vue?vue&type=style&index=0&id=225a54c1&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "225a54c1",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QVideo from 'quasar/src/components/video/QVideo.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QSlideTransition from 'quasar/src/components/slide-transition/QSlideTransition.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QVideo,QBtn,QImg,QInfiniteScroll,QSpinnerDots,QTooltip,QChip,QSlideTransition,QCardSection,QScrollArea,QIcon,QSeparator,QCardActions});
