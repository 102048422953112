<template lang="pug">
//- .row.justify-center
  //- .col-2
.locale-changer
  q-select(borderless dense emit-value map-options v-model="$root.$i18n.locale" @input="changed" :options='langs' behavior='menu')
    template(v-slot:prepend)
      q-icon(name="translate" size="small" color="primary",)
</template>

<script>
  let langmap = require("langmap");
  export default {
    name: "locale-changer",
    created() {
      this.changed(this.$root.$i18n.locale);
    },
    methods: {
      codeToLang(code) {
        let map = langmap[code];
        return map ? map["nativeName"] : code;
      },
      async changed(val) {
        if (val == "en") val = "en-us";
        await import(
          /* webpackInclude: /(ar|fr|en-us|es)\.js$/ */
          "quasar/lang/" + val
        ).then((lang) => {
          this.$q.lang.set(lang.default);
        });
      },
    },
    computed: {
      langs() {
        let lngs = [];
        for (let l of this.$i18n.availableLocales)
          lngs.push({
            label: this.codeToLang(l),
            value: l,
          });
        return lngs;
      },
    },
  };
</script>
