<template lang="pug">
div
  q-dialog(v-model="playvideo")
    q-card(style="width: 700px; max-width: 90vw").z-max
      q-video(
        v-if="currentvideo.youtube",
        :src="'https://www.youtube.com/embed/' + currentvideo.youtube",
        :ratio="16 / 9"
      )
      q-video(
        v-if="!currentvideo.youtube",
        :src="getNonTubeLink(currentvideo)",
        :ratio="16 / 9"
      ) 
      q-btn.full-width(
        color="grey-5",
        dense,
        flat,
        type="a",
        :href="getNonTubeLink(currentvideo)",
        target="_blank"
      ) {{ $t('no_youtube') }}

  q-dialog(v-model="playimg")
    q-card(style="width: 700px; max-width: 90vw")
      q-img(contain, :src="getS3Link(currentimg.src)", :ratio="16 / 9")

  .q-pa-lg.flex.flex-center

  //- div {{socialMedia}}

  q-infinite-scroll(scroll-target="body", ref="scroller", @load="onLoad" style="padding-bottom: 275px;")
    template(v-slot:loading)
      .row.justify-center.q-my-md
        q-spinner-dots(color="primary", size="40px")

    .text-center.q-my-lg(v-if="!loading && !submissions.length") {{ $t('nosubmissions') }}

    .row.q-col-gutter-md
      .col-sm-6.col-md-4.col-12(v-for="submission of submissions", :key="submission.id" v-if="submission.subtype!=='text'")

        //- .text-right.text-overline.text-grey-5 {{ getRelativeTime(submission.createdAt) }}
        q-card(bordered, flat :class="{'border-me': currentselection===submission.id, 'lighter':(currentselection != '' && currentselection!==submission.id)}").fit.mycard
          
          .column.fit
            q-img.bg-grey-4.cursor-pointer(:ratio="16 / 9",
                @click="select(submission)",
                :src="submission.thumbnail",
                cover)
            .col-auto.q-pa-sm
              .text-h6
                q-btn.float-right(
                  v-if="getYoutube(submission)",
                  round,
                  flat,
                  target="_blank",
                  :icon="fabYouTube",
                  type="a",
                  :href="'https://www.youtube.com/v/' + getYoutube(submission).youtube",
                  color="red"
                )
                  q-tooltip {{ $t('view_on_youtube') }}
                q-chip.float-right(dense color="black", unelevated  outline) {{ codeToLang(submission.language).englishName }}
                //- q-btn.float-right(
                //-   round,
                //-   flat,
                //-   icon="cloud_download",
                //-   :disable="submissionDownloadInProgress",
                //-   @click="downloadAll(submission)"
                //- )
                //-   q-tooltip {{ $t('download_assets') }}
                //- span {{ getTitle(submission) }}
                .text-body1
                  .ellipsis(v-if="submission.submitted_by && submission.submitted_by.user_name") {{ submission.submitted_by.user_name }}
                  span(v-for="team in submission.team", :key="team.name") &amp; {{ team.name }}
                
              
            .col.q-pa-sm
              .row
                .col-auto
                  q-chip.q-pa-md.ellipsis.full-width(square unelevated color="primary", outline) {{ codeToRegion(submission.region) }}
                .col-auto
                  q-chip.q-pa-md.ellipsis(
                      square
                      color="primary",
                      outline,  
                      unelevated
                    ) {{ $t('tags.' + submission.oneTag) }}
                .col-auto(v-for="(tag,index) of submission.tags",
                    :key="index")
                  q-chip.q-pa-md(
                      square
                      color="primary",
                      outline,  
                      unelevated
                    ) {{ $t('tags.' + tag) }}
            
            .col-auto.q-pa-sm
              .col-auto
                q-btn.fit(
                    flat
                    dense
                    align="between"
                    color="primary"
                    :outline="currentselection != submission.id"
                    unelevated
                    :icon-right="currentselection == submission.id ? 'keyboard_arrow_up' : 'keyboard_arrow_down'",
                    @click="select(submission)"
                    :label="$t('Show assets')"
                    no-caps
                  )

                    q-tooltip {{ $t('show_assets') }}
          
          
        q-slide-transition.bg-grey-2.absolute(style="left:0;right:0;z-index:2")
          q-card-section.border-top(v-show="currentselection==submission.id" style="height:330px;")
            .row.justify-center.q-my-md.absolute-center(v-if="subloading==true")
              q-spinner-dots(color="primary", size="40px")
            q-scroll-area(horizontal, style="height: 305px" )
              .row.no-wrap
                Caption(
                  :submission="submission",
                  :lang="langswitch",
                  :metadata="metadata",
                  v-on:langswitch="switchlang",
                  :socialMedia="socialMedia"
                )
                //- div {{sortMedia(submission)}}
                q-card.column.on-left(
                  v-for="media of sortMedia(submission)",
                  :key="media.id",
                  v-if="canDisplay(media)",
                  bordered,
                  flat
                )
                  q-img.cursor-pointer(
                    :ratio="16 / 9",
                    v-if="media.type == 'image'",
                    :src="getS3Link(media.src)",
                    style="width: 275px",
                    contain,
                    @click="currentimg = media; playimg = true"
                  )
                  q-img.cursor-pointer(
                    :ratio="16 / 9",
                    v-if="media.type == 'hd_video'",
                    :src="getThumbnail(submission)",
                    style="width: 267px",
                    @click="currentvideo = media; playvideo = true",
                    ripple
                  )
                    q-icon.absolute-center(
                      name="play_arrow",
                      color="white",
                      size="lg"
                    )
                  q-img.cursor-pointer(
                    :ratio="1",
                    v-if="media.type == 'square_video'",
                    :src="getThumbnail(submission)",
                    style="width: 150px",
                    @click="currentvideo = media; playvideo = true"
                  )
                    q-icon.absolute-center(
                      name="play_arrow",
                      color="white",
                      size="lg"
                    )
                  q-card-section.col.scroll(
                    :class="{ 'text-grey-5': !hasLang(media) }",
                    style="min-width: 300px; max-width: 300px; max-height: 150px",
                    v-if="media.type == 'social'"
                  ) {{ getCurrentLang(media) }}

                  q-card-section.q-pa-xs
                    q-icon(
                      :name="getTypeIcon(media.type)",
                      color="secondary",
                      size="sm"
                    )
                    .float-right
                      q-chip.q-ml-xs(
                        @click="langswitch = lang.targetLang || lang.srcLang",
                        dense,
                        clickable,
                        :disable="!canUseLang(lang)",
                        color="secondary",
                        outline,
                        v-for="lang of media.langs",
                        :key="lang.id",
                        :color="lang.targetLang == langswitch || (langswitch == lang.srcLang && lang.original) ? 'secondary' : 'grey'"
                      )
                        span {{ lang.targetLang || lang.srcLang }}
                        span(v-if="lang.original") *
                  q-separator
                  q-card-actions.col-auto(align="between")
                    q-btn(
                      flat,
                      icon="download",
                      @click="download(media)",
                      v-if="canDownload(media)"
                    )
                    q-btn(
                      flat,
                      icon="copy_all",
                      @click="copy(media)",
                      v-if="canCopy(media)"
                    )
                    q-btn(
                      flat,
                      icon="link",
                      v-if="media.src",
                      @click="copyLink(media.src)"
                    )
        
</template>

<script>
  // import size from "lodash/size";
  import includes from "lodash/includes";
  import map from "lodash/map";
  import last from "lodash/last";
  import find from "lodash/find";
  import sortBy from "lodash/sortBy";
  import { DateTime } from "luxon";
  import { copyToClipboard, openURL } from "quasar";
  import { getWirefeed, getWirefeedMedia } from "../db";
  import langmap from "langmap";
  // import axios from "axios";
  import { ifrcList as regions } from "ifrc-list";
  import Caption from "@/components/Caption";
  import { fabYoutube } from "@quasar/extras/fontawesome-v5";

  export default {
    name: "Submissions",
    components: { Caption },
    created() {
      this.fabYouTube = fabYoutube;
    },
    data: () => ({
      playvideo: false,
      submissions: [],
      playimg: false,
      currentvideo: {},
      currentimg: {},
      langswitch: "en",
      loading: true,
      submissionDownloadInProgress: false,
      currentselection: "",
      rawcaptions: {},
      subloading: false,
    }),
    methods: {
      getYoutube(submission) {
        let yt = find(submission.media, { type: "hd_video" });
        // console.log(yt);
        return yt?.youtube ? yt : false;
      },
      switchlang(lang) {
        // console.log(lang);
        this.langswitch = lang;
      },
      getNonTubeLink(media) {
        // console.log(media);
        if (!media) return "";
        if (!media.src) return "";
        return `${this.metadata.cloudfronturl}${media.src
          .replace("s3://", "")
          .replace(this.metadata.bucketname, "")
          .replace("hd_video", "hd_video_transcoded")}`;
      },
      sortMedia(submission) {
        if (submission.media) {
          return sortBy(submission.media, (f) => {
            if (f) {
              switch (f.type) {
                case "image":
                  return 1;
                default:
                  return -1;
              }
            }
          });
        }
      },
      getThumbnail(submission) {
        // let raw = find(submission.media, { type: "raw" });
        // console.log(this.getS3Link(submission.thumbnail));
        return submission.thumbnail;
        // return `${this.getS3Link(raw.src).replace(".mp4", "")}.0000002.jpg`;
      },
      codeToRegion(code) {
        let country = find(regions, { code: code });
        return `${country.flag} ${country.country}`;
      },
      codeToLang(code) {
        return langmap[code];
      },
      async onLoad(index, done) {
        await this.getSubmissions(done);
      },
      async getSubmissions(done) {
        this.loading = true;
        try {
          let query = {
            last: lastl,
            limit: 21,
            phase: this.terms.phase,
          };

          if (this.terms.lang) {
            query.language = this.terms.lang;
          }

          if (this.terms.region) {
            query.region = this.terms.region;
          }

          // // for each
          if (this.terms.tags) {
            let tt = [];
            for (const [k, v] of Object.entries(this.terms.tags)) {
              // console.log(k);
              if (v) tt.push(k);
            }
            let tags = tt;
            // console.log(tags);
            if (tags.length) {
              query.tags = tags;
            }
          }

          let lastl = null;

          if (this.submissions.length > 0) {
            lastl = last(this.submissions);
            // console.log(lastl);
            query.last = lastl.id;
          }

          const tmpsubs = (await getWirefeed(query)).data;

          // console.log(tmpsubs);

          tmpsubs.forEach((doc) => {
            // console.log(doc);
            this.submissions.push(doc);
          });

          let length = tmpsubs.length;

          // this.$unbind("tmpsubs", true);

          // Prevent from receiving updates automatically

          if (done) done(length == 0);
          else this.$refs.scroller.stop();
        } catch (e) {
          console.log(e);
          this.showError(e);
        }
        this.loading = false;
      },
      async copyLink(src) {
        try {
          let url = `${this.metadata.cloudfronturl}${src
            .replace("s3://", "")
            .replace(this.metadata.bucketname, "")}`;
          await copyToClipboard(url);
          this.$q.notify({
            // position: "bottom",
            // title: this.$t("success"),
            type: "positive",
            // icon: 'check_circle_outline'
            // message: this.$t("copiedtoclipboard"),
          });
          // console.log("copied");
        } catch (e) {
          console.log("problem copying", e);
          this.showError(e);
        }
      },
      async select(submission) {
        if (this.currentselection == submission.id) this.currentselection = "";
        else {
          this.subloading = true;
          this.currentselection = submission.id;
          // console.log("loading media");
          try {
            const media = await getWirefeedMedia({ id: submission.id });
            // console.log("GOT MEDIA");
            console.log(submission);
            this.$set(submission, "media", media.data);
          } catch (e) {
            console.log(e.toString());
            this.showError(e.toString());
          } finally {
            this.subloading = false;
          }
        }
      },
      // showDownloadInProgressNotif() {
      //   return this.$q.notify({
      //     type: "ongoing",
      //     message: this.$t("download_processing"),
      //     position: "bottom-right",
      //   });
      // },
      // requestSubmissionZip(isPoll, submissionId) {
      //   return axios.request({
      //     url: `https://ahm9x0vrfl.execute-api.ap-southeast-2.amazonaws.com/default/YouthInnovationSubmissionZip`,
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "text/plain",
      //     },
      //     data: JSON.stringify({
      //       key: "DEV_KEY",
      //       submissionId: submissionId,
      //       isPoll: isPoll,
      //     }),
      //   });
      // },
      // async downloadAll(submission) {
      //   this.submissionDownloadInProgress = true;

      //   const dismiss = this.showDownloadInProgressNotif();
      //   // This request is likely to fail, because the process
      //   // of creating the zip takes longer than the API Gateway
      //   // will stay open. (We still need to make the request,
      //   // to initiate creating the zip though)
      //   this.requestSubmissionZip(false, submission.id).catch(() =>
      //     console.log("processing download")
      //   );

      //   await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait 2 seconds before beginning polling

      //   const dataOrError = await poll({
      //     fn: () =>
      //       this.requestSubmissionZip(true, submission.id).catch((e) => ({
      //         error: e.body.error,
      //       })),
      //     validate: (result) =>
      //       result.error || (result.data && result.data.path), // Short circuit on errors
      //     interval: 5 * 1000, // Poll every 5 seconds,
      //     maxAttempts: 96,
      //   }).catch(() => {
      //     return { error: "You download failed, please try again" };
      //   });

      //   this.submissionDownloadInProgress = false;
      //   dismiss();

      //   if (dataOrError.error) {
      //     this.$q.notify({
      //       type: "negative",
      //       message: dataOrError.error,
      //     });
      //   }

      //   if (dataOrError.data) {
      //     const src = dataOrError.data.path;

      //     openURL(
      //       `${this.metadata.cloudfronturl}/${src.replace(
      //         "s3://youth-innovation/",
      //         ""
      //       )}`
      //     );
      //   }
      // },
      canUseLang(lang) {
        // console.log(lang);
        return lang.original || lang.status == "finalised";
      },
      hasLang(media) {
        let langs = map(media.langs, (m) => {
          if (m.targetLang && m.status == "finalised") return m.targetLang;
          else if (m.srcLang && m.original) return m.srcLang;
          else return null;
        });

        return includes(langs, this.langswitch);
      },
      download(media) {
        try {
          switch (media.type) {
            case "image":
            case "longform":
            case "hd_video":
            case "square_video":
              openURL(this.getS3Link(media.src));
              break;
          }
        } catch (e) {
          this.showError(e);
        }
      },
      async copy(media) {
        try {
          await copyToClipboard(this.getCurrentLang(media));
          this.$q.notify({
            type: "positive",
          });
        } catch (e) {
          console.log("problem copying", e);
          this.showError(e);
        }
      },
      canDownload(media) {
        return includes(
          ["image", "hd_video", "square_video", "caption"],
          media.type
        );
      },
      canCopy(media) {
        return includes(["social", "caption", "title"], media.type);
      },
      canDisplay(media) {
        if (media) {
          return includes(
            ["image", "social", "hd_video", "square_video"],
            media.type
          );
        }
      },
      getTypeIcon(type) {
        switch (type) {
          case "hd_video":
            return "hd";
          case "image":
            return "photo";
          case "square_video":
            return "crop_square";
          case "caption":
            return "format_align_left";
          case "social":
            return "short_text";
          default:
            return "";
        }
      },
      getRelativeTime(time) {
        console.log(time);
        return DateTime.fromSeconds(parseInt(time._seconds)).toRelative();
      },
      getMediaField(type, media) {
        let lf = find(media, { type: type });
        // console.log(type);
        if (!lf) return null;
        return lf;
      },
      getCurrentLang(media) {
        try {
          if (!media || !media.langs) return "";

          // console.log(media.langs);

          let target = find(media.langs, { targetLang: this.langswitch });
          // console.log(target);
          if (
            typeof target != "undefined" &&
            ((target.status && target.status == "finalised") || !target.status)
          )
            return target.targetText;
          else if (media.langs.length)
            return find(media.langs, { original: true }).srcText;
          else return "en";
        } catch {
          return "en";
        }
      },
      getS3Link(src) {
        if (!src) return "";
        let url = `${this.metadata.cloudfronturl}${src
          .replace("s3://", "")
          .replace(this.metadata.bucketname, "")}`;
        return url;
      },
      getTitle(submission) {
        try {
          let userName = "";
          if (submission.submitted_by && submission.submitted_by.user_name)
            userName = submission.submitted_by.user_name;
          else userName = "Youth Innovator";
          return this.socialMedia[
            "youtube_title_" + submission.phase
          ]?.en?.replace("{name}", userName);
        } catch {
          return (
            this.socialMedia["youtube_title_" + submission.phase]?.en?.replace(
              "{name}",
              ""
            ) ?? "NO TITLE"
          );
        }
      },
    },
    watch: {
      terms: {
        deep: true,
        async handler() {
          console.log("Reloading Submissions", this.terms);
          this.currentselection = "";
          this.submissions.splice(0);
          this.$refs.scroller.reset();
          this.$refs.scroller.resume();
          this.$refs.scroller.trigger();
        },
      },
    },
    props: ["terms", "metadata", "socialMedia"],
  };
</script>

<style scoped lang="scss">
  @import "@/styles/quasar.variables.scss";

  .border-top {
    border-top: solid #eee 1px;
  }

  .border-me {
    border: solid 1px $primary;
  }

  .lighter {
    opacity: 0.5 !important;
  }

  .mycard {
    opacity: 1;
    transition: opacity 0.5s;
  }
</style>
