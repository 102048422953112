<template lang="pug">
q-card.column.on-left(v-if="groupCaptions.length", bordered, flat)
  q-card-section.col.scroll(
    style="min-width: 400px; max-height: 150px; max-width: 400px"
  )
    pre(style="white-space: pre-wrap;") {{ captionCurrentLang }}
  q-card-section.q-pa-xs
    q-icon(name="format_align_left", color="secondary", size="sm")
    .float-right
      q-chip.q-ml-xs(
        @click="$emit('langswitch', l)",
        dense,
        clickable,
        color="secondary",
        outline,
        v-for="l of groupCaptions",
        :key="l",
        :color="l == lang ? 'secondary' : 'grey'"
      )
        span {{ l }}
        span(v-if="isOriginal(l)") *
  q-separator
  q-card-actions.col-auto(align="between")
    q-btn(
      type="a",
      flat,
      icon="download",
      download="transcript.txt",
      :href="'data:text/plain;charset=utf-8,' + encodeURIComponent(captionCurrentLang)"
    )
</template>

<script>
  import vttToJson from "vtt-json";
  import axios from "axios";
  // import filter from "lodash/filter";
  import find from "lodash/find";
  import map from "lodash/map";
  import includes from "lodash/includes";
  // import uniq from "lodash/uniq";

  export default {
    props: ["submission", "lang", "metadata", "socialMedia"],
    data: () => {
      return {
        rawcaptions: {},
      };
    },
    watch: {
      lang: {
        handler() {
          this.getCaptions();
        },
      },
      submission: {
        immediate: true,
        handler() {
          if (this.groupCaptions.length) {
            try {
              this.getCaptions();
            } catch (e) {
              console.log(e);
            }
          }
        },
      },
    },
    computed: {
      captionCurrentLang() {
        let titletext = "";
        let userName = "";
        if (
          this.submission.submitted_by &&
          this.submission.submitted_by.user_name
        )
          userName = this.submission.submitted_by.user_name;
        else userName = "Innovator";
        try {
          titletext = this.socialMedia[
            "youtube_title_" + this.submission.phase
          ].en.replace("{name}", userName);
        } catch {
          titletext = this.socialMedia[
            "youtube_title_" + this.submission.phase
          ].en.replace("{name}", "");
        }

        let names = [userName, ...map(this.submission.team, "name")];

        // console.log(this.rawcaptions[this.lang]);

        // console.log(names);

        return `**${titletext.trim()}**\r\n${names.join(", ")}\r\n\r\n${map(
          this.rawcaptions[this.lang],
          "part"
        ).join(". ")}\r\nby ${this.translatedBy}`;
      },
      groupCaptions() {
        // console.log(this.submission.translatedBy);
        if (this.submission.translatedBy) {
          return Object.keys(this.submission.translatedBy);
        } else return [];

        // let captions = filter(this.submission.media, { type: "caption" });
        // return uniq(map(captions, "targetLang"));
      },
      translatedBy() {
        return this.submission.translatedBy[this.lang];
      },
    },
    methods: {
      isOriginal(lang) {
        return lang == this.submission.language;
      },
      getTitlesSrc() {
        let hd_video = find(this.submission.media, { type: "hd_video" });
        if (!hd_video.src) return "";
        return `${this.metadata.cloudfronturl}${hd_video.src
          .replace("s3://", "")
          .replace(this.metadata.bucketname, "")
          .replace("hd_video.mp4", `youtube_${this.lang}.vtt`)}`;
      },
      async getCaptions() {
        try {
          if (includes(this.groupCaptions, this.lang)) {
            if (!this.rawcaptions[this.lang]) {
              // console.log(this.getTitlesSrc());
              let data = (await axios.get(this.getTitlesSrc())).data;
              // console.log(data);
              this.$set(this.rawcaptions, this.lang, await vttToJson(data));
            }
          }
          // console.log(this.rawcaptions[this.lang]);
        } catch (e) {
          // console.log(e);
          // console.log(e);
          this.$set(this.rawcaptions, this.lang, [
            { part: "Pending download of subtitles..." },
          ]);
        }
      },
    },
  };
</script>
