<template lang="pug">
div(v-intersection="scroll_options").bg-grey-2.q-pa-lg
  .row.justify-between.items-center
    .col-12.col-md-auto.text-center.q-mb-sm
      .text-h2 {{ $t('video-submissions-to-limitless-2024') }}
    //- .col-md-auto.text-center.col-12.q-mb-sm
      //- q-btn(unelevated color="secondary" size="lg" target="_BLANK" type="a" href="https://trello.com/invite/b/mAmDUQKn/ATTI99300546147946e88402cc1ef8e1fcf8C1790B54/limitless-communications-package") {{ $t('communication-materials') }}

  //- div(v-if="isFixed" :class="{'push':isFixed}" :style="{'height':(filterHeight+5)+'px'}")  
  .row.q-mt-md.q-col-gutter-md
    .col-12.col-md-6
      .row
        .col 
          .text-body2.text-bold {{ $t('select-limitless-phase') }}
      .row
        .col
          .q-py-md
            .row.q-col-gutter-sm
              .col-auto(v-for="phase in phaseoptions")
                q-btn(unelevated :outline="terms.phase != phase.value" color="primary" @click="terms.phase = phase.value" no-caps) {{phase.label}}
    .col-12.col-md-6
      .row
        .col
          .text-body2.text-bold {{ $t('select-country-or-language') }}
      .row.q-col-gutter-md
        .col
          .q-py-md
            q-select(emit-value map-options outlined v-model="terms.lang" clearable :options='langs' dense behavior='menu')
              //- template(v-slot:prepend)
              //-   q-icon(name="translate")
              //- template(v-slot:no-option)
              //-   q-item
              //-     q-item-section.text-grey {{$t('no_results')}}
        .col
          .q-py-md
            q-select(emit-value map-options outlined v-model="terms.region" clearable :options='regions' behavior='menu' dense)
                  //- template(v-slot:prepend)
                  //-   q-icon(name="language")
                  
                  template(v-slot:selected-item="scope")
                    q-item(v-bind="scope.itemProps" v-on="scope.itemEvents")
                      q-item-section(avatar).flag {{scope.opt.flag}}
                      q-item-section
                        q-item-label(:lines="2") {{scope.opt.label}}

                  template(v-slot:option="scope")
                    q-item(v-bind="scope.itemProps" v-on="scope.itemEvents")
                      q-item-section(avatar).flag {{scope.opt.flag}}
                      q-item-section
                        q-item-label {{scope.opt.label}}
    
  .row
    .col
      .row
        .col
          .q-pb-md
            .text-body2.text-bold {{ $t('select-tags') }}
      .row
        .col
          .row.q-col-gutter-xs
            .col-auto(v-for="(val,tag) of tags")
              q-btn.q-px-sm(:outline="!terms.tags[tag]" no-caps dense @click="terms.tags[tag] = !terms.tags[tag]" unelevated :selected.sync="terms.tags[tag]" square color="primary"  :key="tag") {{$t('tags.'+tag)}}
          //- q-chip(outline :selected.sync="terms.tags[tag]" square color="primary" v-for="(val,tag) of tags" :key="tag") {{$t('tags.'+tag)}}

  //- div(:class="{'fixed-top':isFixed, 'q-px-md':isFixed, 'z-max':isFixed, 'push':!isFixed, 'bg-white':isFixed}")
  //-   q-resize-observer(@resize="onResize")
  //-   .text-center
  //-     q-card.shunt(flat).inline-block
        
    //- q-card(:flat="!isFixed" bordered)
    //-   q-card-section
    //-     .row.q-col-gutter-sm
    //-         .col-sm-6.col-xs-12
              
    //-         .col-sm-6.col-xs-12
              
    //-     .row.q-my-sm
    //-       .col
    //-         .row.no-wrap.scroll(class="q-gutter-xs")
              
</template>

<script>
  import map from "lodash/map";
  import zipObject from "lodash/zipObject";
  import each from "lodash/each";
  import upperCase from "lodash/upperCase";
  import fill from "lodash/fill";
  import size from "lodash/size";
  import filter from "lodash/filter";
  let langmap = require("langmap");
  //https://github.com/lukes/ISO-3166-Countries-with-Regional-Codes/blob/master/all/all.json
  import { ifrcList as regions } from "ifrc-list";
  const uselangs = [
    "en",
    "ar",
    "bn",
    "es",
    "fa",
    "fr",
    "hi",
    "id",
    "ja",
    "ko",
    "pt",
    "ru",
    // "tr",
    "zh",
    // "vi",
    // "uk",
  ];

  const thresholds = [0];

  export default {
    name: "SubFilter",
    props: ["terms", "data", "metadata"],
    data() {
      return {
        lang_options: [],
        region_options: [],
        selected_tags: {},
        isFixed: false,
        filterHeight: 0,
        scroll_options: {
          handler: this.onIntersection,
          cfg: {
            threshold: thresholds,
          },
        },
      };
    },
    watch: {
      tags: {
        immediate: true,
        handler() {
          this.selected_tags = this.tags;
        },
      },
      selected_tags: {
        handler() {
          this.terms.tags = this.selected_tags;
        },
      },
    },
    computed: {
      phaseoptions() {
        if (!this.metadata.phases) return [];
        return map(filter(this.metadata.phases, "visible"), (m) => {
          return {
            value: m.code,
            label: this.$t("phase." + m.code),
          };
        });
      },
      tags() {
        // let alltags = uniq(flatten(map(this.data, "tags")));
        // let alltags = this.metadata.tag_options;
        if (this.metadata.tag_options) {
          return zipObject(
            Object.keys(this.metadata.tag_options),
            fill(Array(size(this.metadata.tag_options)), false)
          );
        } else {
          return [];
        }
        // return zipObject(alltags, fill(Array(size(alltags)), false));
      },
      langs() {
        let tmp = [];
        each(uselangs, (a) => {
          tmp.push({
            value: a,
            label: `${this.codeToLang(a)["nativeName"]} (${
              this.codeToLang(a)["englishName"]
            })`,
          });
        });
        return tmp;
      },
      regions() {
        return map(regions, (r) => {
          return {
            value: r.code,
            flag: r.flag,
            label: r.country,
          };
        });
      },
    },
    methods: {
      onResize(entry) {
        this.filterHeight = entry.height;
      },
      onIntersection(entry) {
        // console.log(entry);
        this.isFixed = !entry.isIntersecting;
      },
      codeToLang(code) {
        return langmap[code];
      },
      codeToRegion(code) {
        let reg = find(regions, { "alpha-2": upperCase(code) });
        return reg.name;
      },
      filterFnLang(val, update) {
        if (val === "") {
          update(() => {
            this.lang_options = this.langs;
          });
          return;
        }

        update(() => {
          const needle = val.toLowerCase();
          this.lang_options = this.langs.filter(
            (v) => v.label.toLowerCase().indexOf(needle) > -1
          );
        });
      },
      filterFnRegion(val, update) {
        if (val === "") {
          update(() => {
            this.region_options = this.regions;
          });
          return;
        }

        update(() => {
          const needle = val.toLowerCase();
          this.region_options = this.regions.filter(
            (v) => v.label.toLowerCase().indexOf(needle) > -1
          );
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  // @import "@/styles/quasar.variables.scss";

  .shunt {
    margin-bottom: -20px;
    padding-bottom: 17px;
  }

  .nopadding {
    /* padding:2px; */
  }

  .push {
    /* margin-top: 300px; */
  }

  .flag {
    font-size: 2em;
  }

  // .border-btn {
  //   border: 1px solid $primary;
  // }
</style>
