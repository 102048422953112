// Conveniently import this file anywhere to use db

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  functions_url: process.env.VUE_APP_FUNCTION_URL,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
};

const app = firebase.initializeApp(firebaseConfig);

const functions = app.functions(process.env.VUE_APP_REGION);
export const db = app.firestore();

export const getWirefeed = functions.httpsCallable("getWirefeed");
export const getWirefeedMedia = functions.httpsCallable("getWirefeedMedia");

if (process.env.VUE_APP_EMULATORS) {
  console.log("USING EMULATORS");
  functions.useEmulator("localhost", 5001);
  db.useEmulator("localhost", 8082);
  // storage.useEmulator("localhost", 9199);
  // auth.useEmulator("http://localhost:9099");
}
