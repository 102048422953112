import Vue from "vue";
import App from "./App.vue";
import "./quasar";
import i18n from "./i18n";
import { firestorePlugin } from "vuefire";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const serialize = (doc) => {
  const data = doc.data();
  Object.defineProperty(data, "id", { value: doc.id });
  Object.defineProperty(data, "_doc", { value: doc });
  return data;
};

Vue.use(firestorePlugin, {
  serialize,
});

Vue.mixin({
  methods: {
    showError() {
      this.$q.notify({
        // position:'bottom',
        type: "negative",
        title: this.$t("failure"),
      });
    },
  },
});

const router = new VueRouter({
  mode: "history",
});

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
