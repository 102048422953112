<template lang="pug">
q-layout.overflow-hidden(view="lHh Lpr lff")
  q-header(elevated style="height:84px;").bg-white
    q-toolbar.items-center.full-height
      .col-auto(style="padding-top:6px;padding-left:6px;")
        img.logo(src="~@/assets/img/logo_green.webp" alt="Limitless Logo")
      q-space
      LocaleSwitcher
      q-btn.on-right(unelevated color="secondary" size="lg" target="_BLANK" type="a" href="https://trello.com/invite/b/mAmDUQKn/ATTI99300546147946e88402cc1ef8e1fcf8C1790B54/limitless-communications-package") {{ $t('communication-materials') }}

  //- q-dialog(v-model="instructions" full-width full-height @hide="setLocal(true)" @show="setLocal(false)")
  //-   InstructionsDialog

  //- q-drawer.z-max.absolute(v-model="left" side="left" overlay elevated :width="400" behavior="mobile")
  //-   Instructions

  //- q-btn.z-top.bg-primary.text-white.absolute-center(v-show="!instructions" size="lg" @click="instructions=true" style="top:120px;" rounded) VIEW COMMUNICATION MATERIALS

  //- .row.justify-end.q-pa-sm
    
  q-page-container
    q-page(padding)
      SubFilter(
          :terms="terms",
          :metadata="metadata"
        )
      Submissions(:terms="terms", :metadata="metadata" :socialMedia="socialMedia")
  //- q-page-sticky(position="bottom-left" :offset="[18, 18]")
  //-   q-btn(fab icon="help" color="primary" @click="left=true")
</template>

<script>
  import Submissions from "./components/Submissions.vue";
  import SubFilter from "./components/SubFilter.vue";
  import LocaleSwitcher from "./components/LocaleSwitcher.vue";
  // import Instructions from "@/components/Instructions";
  // import InstructionsDialog from "@/components/InstructionsDialog";
  import { db } from "./db";

  export default {
    name: "App",

    components: {
      Submissions,
      SubFilter,
      LocaleSwitcher,
      // Instructions,
      // InstructionsDialog,
    },
    methods: {
      setLocal(val) {
        if (val) localStorage.hideinstructions = true;
        else delete localStorage.hideinstructions;
      },
    },
    async created() {},
    async mounted() {
      // console.log(localStorage.hideinstructions);
      if (localStorage.hideinstructions == "true") {
        this.instructions = false;
      }

      // if (this.$route.query.lang) {
      //   this.terms.lang = this.$route.query.lang;
      // }

      // if (this.$route.query.region) {
      //   this.terms.region = this.$route.query.region;
      // }

      try {
        this.metadata = (
          await db
            .collection("config")
            .doc("meta")
            .get()
        ).data();

        this.socialMedia = (
          await db
            .collection("config")
            .doc("socialMedia")
            .get()
        ).data();

        // console.log(this.metadata.current_phase);
        this.terms.phase = this.metadata.current_phase;
      } catch (e) {
        this.showError(e);
      }
    },
    meta() {
      return {
        title: this.$t("title"),
      };
    },
    data() {
      return {
        left: false,
        terms: { phase: -1 },
        loading: false,
        metadata: {},
        socialMedia: {},
        instructions: true,
      };
    },
  };
</script>

<style scoped>
  /* .header {
    background: url("~@/assets/header.png") no-repeat;
    background-size: 800px auto;
    background-position-x: 55%;
  } */

  /* .hero { */
  /* margin-top: 300px; */
  /* min-height:200px; */
  /* } */

  .bg-transparent {
    background-color: transparent !important;
  }

  .logo {
    opacity: 1;
    transition: opacity 0.3s;
    width: 120px;
    height: 43px;
  }
</style>
